<template>
    <vue-skeleton-loader
        type="rect"
        animation="fade"
        class="barChart"
        width="100%"
        v-if="barGraphLoader"
    />
    <BarChart 
        :chart-data="testData"
        :chart-options="options"
        :height="height"
        :css-classes="cssClasses"
        class="barChart"
        :styles="styles"
        v-else
    />
</template>

<script>
import {mapGetters} from 'vuex';
import { Bar as BarChart } from 'vue-chartjs/legacy';

export default {
    name: "chart",
    components: {BarChart},
    data: ()=>({
        testData: {
            // labels: ['JAN', 'FEB', 'MAR', 'APR','MAY', 'JUN', 'AUG', 'SEP','OCT', 'NOV', 'DEC'],
            labels: ['Jan', 'Feb', 'Mar', 'Apr','May', 'Jun','Jul', 'Aug', 'Sep','Oct', 'Nov', 'Dec'],
            datasets: [
                {
                    label: 'Sale',
                    data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11],
                    backgroundColor: ['#14223d'],
                },
                {
                    label: 'Expense',
                    data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11],
                    backgroundColor: ['#fd4e5d'],
                }
            ],
        },
        options: {
            plugins: {
                legend:{
                    display: true,
                    // position: 'right',
                    fullSize: true,
                    labels: {
                        boxHeight: 25,
                        boxWidth: 25,
                        // pointStyle: 'circle'
                        usePointStyle: true,
                        padding: 15,
                    }
                }
            },
            maintainAspectRatio: false,
            responsive:true
        }
    }),
    props:{
        year:{
            type: String | Number,
            default: ''
        },
        height:{
            type: Number,
            default: 400
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => {}
        },
    },
    computed:{
        ...mapGetters({
            settings:'settings_module/settings',
            barGraph:'bar_graph_module/barGraphData',
            barGraphYear:'bar_graph_module/storeYear',
            barGraphLoader:'bar_graph_module/isPending',
        }),
    },
    watch:{
        'year':{
            handler:function(value){

                this.$store.dispatch('bar_graph_module/fetchBarGraphRequest',value);
                
            },
            deep:true,
        },
        // 'barGraphYear':{
        //     handler:function(value){
        //         this.$store.dispatch('bar_graph_module/fetchBarGraphRequest',value)
        //     }, 
        //     deep:true
        // },
        'barGraph':{
            handler:function(value){

                this.testData.datasets[0].data = value.sales
                this.testData.datasets[1].data = value.expenses

            },
            deep:true
        },
    },
    methods:{

    },
    async beforeMount(){
        
        if(!this.barGraph || this.barGraph.length == 0 || this.barGraph.error){

            if(this.year != ''){

                this.$store.dispatch('bar_graph_module/fetchBarGraphRequest',this.year);

            }

        }else{

            this.testData.datasets[0].data = this.barGraph.sales;
            this.testData.datasets[1].data = this.barGraph.expenses;

        }

    }
}
</script>
<style scoped>
    @media screen and (min-width:1701px){
        .barChart{
            height: 400px;
        }
    }
    @media screen and (max-width:1700px) and (min-width:1601px) {
        .barChart{
            height: 360px;
        }
    }
    @media screen and (max-width:1600px) and (min-width:1401px) {
        .barChart{
            height: 388px;
        }
    }

    @media screen and (max-width:1400px) and (min-width:1025px) {
        .barChart{
            height: 360px;
        }
    }
    @media screen and (max-width:1024px) and (min-width:768px) {
        .barChart{
            height: 418px;
        }
    }

</style>