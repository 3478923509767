<template>
    <vue-skeleton-loader
        type="rect"
        width="100%"
        class="lineChart"
        animation="fade"
        v-if="lineGraphLoader"
    />
    <LineChart 
        :chart-data="testData"
        :chart-options="options"
        :height="height"
        :css-classes="cssClasses"
        :styles="styles"
        class="lineChart"
        v-else
    />
</template>

<script>
import {mapGetters} from 'vuex';
import { Line as LineChart } from 'vue-chartjs/legacy';
import Chart from 'chart.js/auto';

export default {
    name: "chart",
    components: {
        LineChart
    },
    data: ()=>({
        testData: {
            labels: ['JAN', 'FEB', 'MAR', 'APR','MAY', 'JUN', 'AUG', 'SEP'],
            datasets: [
                {
                    label: 'CUSTOMERS',
                    data: [12, 22, 18, 36, 45, 27, 17],
                    backgroundColor: '#fd4e5d',
                    borderColor: '#fd4e5d',
                },{
                    label: 'VISITORS',
                    data: [28, 41, 38, 42, 27, 18, 39, 42],
                    backgroundColor: '#14223d',
                    borderColor: '#14223d',
                },
            ],
        },
        options: {
            plugins: {
                legend:{
                    display: true,
                    position: 'bottom',
                    fullSize: true,
                    labels: {
                        boxHeight: 10,
                        boxWidth: 10,
                        // pointStyle: 'circle'
                        usePointStyle: true,
                        padding: 15
                    }
                }
            },
            responsive:true,
            maintainAspectRatio: false,
        }
    }),
    computed:{
        ...mapGetters({
            lineGraph:'line_graph_module/lineGraphData',
            lineGraphLoader:'line_graph_module/isPending',
            lineGraphFilter:'line_graph_module/selectedFilter',
            rangeFrom:'line_graph_module/from',
            rangeTo:'line_graph_module/to',
        }),
    },
    watch:{
        'lineGraph':{
            handler:function(value){

                this.testData.labels = this.lineGraph.dates
                this.testData.datasets[0].data = value.customers
                this.testData.datasets[1].data = value.visitors

            },
            deep:true
        },
    },
    methods:{

    },
    props:{
        height:{
            type: Number,
            default: 400
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => {}
        },
        
    },
    async beforeMount(){

        if(!this.lineGraph || this.lineGraph.length == 0 || this.lineGraph.error){

            let payload = {
                days: 7,
                filter: 'week',
            }

            this.$store.dispatch('line_graph_module/fetchLineGraphRequest',payload);

        }else{
            
            this.testData.labels = this.lineGraph.dates
            this.testData.datasets[0].data = this.lineGraph.customers
            this.testData.datasets[1].data = this.lineGraph.visitors

        }
        
    }
}
</script>
<style scoped>
    @media screen and (min-width:1701px){
        .lineChart{
            height: 370px;
        }
    }
   
    @media screen and (max-width:1700px) and (min-width:1601px) {
        .lineChart{
            height: 330px;
        }
    }
    @media screen and (max-width:1600px) and (min-width:1401px) {
         .lineChart{
            height: 370px;
        }
    }

    @media screen and (max-width:1400px) and (min-width:1025px) {
         .lineChart{
            height: 345px;
        }
    }
    @media screen and (max-width:1024px) and (min-width:768px) {
         .lineChart{
            height: 345px;
        }
    }

</style>